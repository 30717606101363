@import 'src/apps/web/styles/vendors/material/material-theme-settings';
@import 'src/apps/web/styles/vendors/bootstrap/bootstrap-shared';

table[mat-table] {
  th,
  th.mat-header-cell {
    font-weight: 500;
    color: $app-primary-base;
    font-size: 14px;
    button {
      font-size: 14px;
      text-transform: none;
    }
  }

  &.super-list {
    width: 100%;
    tr.child-row-opened {
      td {
        border-bottom: solid 1px $app-white !important;
      }
    }

    tr.selected-row {
      background-color: $app-accent-light;
    }

    tr.expanded-row {
      height: 0;
      &.collapsed {
        td {
          border: none;
        }
      }
      td {
        overflow: hidden;
        .expanded-column {
          transition: max-height 350ms cubic-bezier(0.4, 0, 0.2, 1);
          &.collapsed {
            max-height: 0;
          }
          &.expanded {
            max-height: 300px;
          }
        }
      }
    }

    .expanded-details-row {
      @extend .d-flex, .flex-wrap, .pt-3;
    }

    .expanded-details-col {
      @extend .col-auto, .mr-4, .mb-3;
    }
  }

  &.table-with-reduced-padding {
    th.mat-header-cell:last-of-type,
    td.mat-cell:last-of-type,
    td.mat-footer-cell:last-of-type {
      padding-right: 4px;
      @include media-breakpoint-up(sm) {
        padding-right: 12px;
      }
    }
    th.mat-header-cell:first-of-type,
    td.mat-cell:first-of-type,
    td.mat-footer-cell:first-of-type {
      padding-left: 12px;
      @include media-breakpoint-up(sm) {
        padding-left: 24px;
      }
    }
  }
  &.without-header {
    thead {
      display: none;
    }
  }
  .cell-with-border {
    border-bottom: 1px solid $app-background;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
  }
}
