@import 'src/apps/web/styles/settings/settings.scss';
@import 'src/apps/web/styles/vendors/material/material-theme-settings';
@import 'src/apps/web/styles/vendors/bootstrap/bootstrap-shared';

.mat-display-1,
.mat-display-2,
.mat-display-3,
.mat-display-4 {
  color: $app-accent-base;
}

h1,
h2,
h3,
h4,
h5 {
  color: $app-primary-base;
}

.mat-caption {
  color: $app-grey;
}

mat-sidenav-content {
  background-color: $app-background;
}

// NOTE: use this to put mat-dialog-actions on bottom
// <div class="dialog">whatever you want<mat-dialog-content>...</mat-dialog-content><mat-dialog-actions></mat-dialog-actions></div>
.dialog {
  height: 100%;
  display: flex;
  flex-direction: column;
  mat-dialog-content {
    flex-grow: 1;
  }
}

.no-padding-dialog {
  .mat-dialog-container {
    padding: 0px !important;
  }
}

.no-padding-form-field {
  .mat-form-field-wrapper {
    padding: 0px !important;
    padding-bottom: 0px !important;
  }
}

.app-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: $app-grey;
}
mat-form-field {
  &.mat-form-field-in-toolbar {
    margin-bottom: -1.2rem;
    font-size: 14px;
    .mat-form-field-flex > .mat-form-field-infix {
      padding: map-get($spacers, 2);
    }
    .mat-form-field-label-wrapper {
      // top: -10px;
    }

    .mat-form-field-label {
      transform: translateY(-0.35rem) scale(1);
      // width: 133.33333%;
    }
  }
}

.mat-paginator-range-label {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.mat-paginator-page-size-label {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

mat-toolbar {
  &.small {
    height: 56px;
  }

  &.mat-toolbar h1 {
    font-size: 24px;
  }
}

mat-sidenav {
  .sidenav-sm,
  .sidenav-md,
  .sidenav-lg {
    width: $sidenav-mobile-width;
    padding-top: $mat-toolbar-height-mobile;
    padding-bottom: $mat-toolbar-height-mobile;

    @include media-breakpoint-up(sm) {
      padding-top: $mat-toolbar-height-desktop;
      padding-bottom: $mat-toolbar-height-desktop;
    }

    mat-toolbar,
    mat-toolbar {
      left: auto !important;
      right: auto !important;
      width: $sidenav-mobile-width !important;
    }
  }

  .sidenav-sm {
    @include media-breakpoint-up(sm) {
      width: $sidenav-sm-desktop-width;
    }

    mat-toolbar,
    mat-toolbar {
      @include media-breakpoint-up(sm) {
        width: $sidenav-sm-desktop-width !important;
      }
    }
  }

  .sidenav-md {
    @include media-breakpoint-up(sm) {
      width: $sidenav-md-desktop-width;
    }

    mat-toolbar,
    mat-toolbar {
      @include media-breakpoint-up(sm) {
        width: $sidenav-md-desktop-width !important;
      }
    }
  }

  .sidenav-lg {
    @include media-breakpoint-up(sm) {
      width: $sidenav-lg-desktop-width;
    }

    mat-toolbar,
    mat-toolbar {
      @include media-breakpoint-up(sm) {
        width: $sidenav-lg-desktop-width !important;
      }
    }
  }
}
